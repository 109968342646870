import React, { Fragment, useEffect } from "react"
import { injectIntl, intlShape } from "react-intl"
import { compose } from "recompose"

import withLayout from "../layout"
import withPageContext from "../pageContext"

import SEO from "../components/SEO"
import Login from "../components/Login"

import { useUser } from "../reducers/userReducer"
import { useAuthChange } from "../firebase"
import { navigate } from "gatsby"
import { getLocalizedPath } from "../i18n"
const IndexPage = ({ intl, locale, pageContext }) => {
  const { userItem, addUser, removeUser } = useUser()
  useAuthChange(addUser, removeUser)

  useEffect(() => {
    if (userItem) {
      navigate(getLocalizedPath("/app/home", pageContext.locale))
    }
  }, [userItem, pageContext.locale])
  return (
    <React.Fragment>
      <SEO
        title={intl.formatMessage({ id: "home.title" })}
        lang={pageContext.locale}
        description={intl.formatMessage({ id: "home.description" })}
      />
      <main>
        <Fragment>
          {!userItem && <Login locale={pageContext.locale} />}
        </Fragment>
      </main>
    </React.Fragment>
  )
}

IndexPage.propTypes = {
  intl: intlShape.isRequired,
}

export default compose(withPageContext, withLayout, injectIntl)(IndexPage)
